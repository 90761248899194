import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Wrapper from '../common/Wrapper'
import Heading from '../Heading/Heading'
import { Typography, Link } from '@material-ui/core'
import Paper from '@material-ui/core/Paper/Paper'

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2)
  },
  actionComponents: {
    marginTop: theme.spacing(1)
  },
  helperText: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    marginBottom: theme.spacing(6)
  }
}))

export interface SelectOption {
  value: string | number
  label: string | number
}

export interface Props {
  /**
   * Called when sign in instead is clicked
   */
  onClickHelper?: Function
  /**
   * Top-level text
   */
  headline?: string
  /**
   * Second-level text
   */
  subtitle?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
  /**
   * Choose default text for sign in instead
   */
  helperText?: string
  /**
   * Choose a design
   */
  card?: boolean
  /**
   * An array of components inside actions area, usually single button
   */
  actionComponents?: React.ReactNode[]
  /**
   * An array of components inside actions area, usually single button
   */
  contentComponents?: React.ReactNode[]
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
}

const Login: React.FC<Props> = ({
  onClickHelper,
  helperText = '',
  headline = 'Log in',
  subtitle = 'Add email and password.',
  maxWidth = 'xs',
  card = false,
  actionComponents = [],
  contentComponents = [],
  style,
  className
}) => {
  const classes = useStyles()

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      {card === true ? (
        <Paper elevation={3}>
          <Heading
            className={classes.heading}
            align="center"
            headline={headline}
            subtitle={subtitle}
          />
          {contentComponents
            ? contentComponents.map((content, index) => {
                return <div key={index}>{content}</div>
              })
            : null}
          {actionComponents
            ? actionComponents.map((button, index) => {
                return (
                  <div key={index} className={classes.actionComponents}>
                    {button}
                  </div>
                )
              })
            : null}
        </Paper>
      ) : (
        <div>
          <Heading
            className={classes.heading}
            align="center"
            headline={headline}
            subtitle={subtitle}
          />
          {contentComponents
            ? contentComponents.map((content, index) => {
                return <div key={index}>{content}</div>
              })
            : null}
          {actionComponents
            ? actionComponents.map((button, index) => {
                return (
                  <div key={index} className={classes.actionComponents}>
                    {button}
                  </div>
                )
              })
            : null}
        </div>
      )}
      {onClickHelper ? (
        <Typography
          variant="caption"
          align="center"
          className={classes.helperText}
        >
          <Link
            onClick={() => onClickHelper()}
            color="inherit"
            underline="always"
            component="button"
          >
            {helperText}
          </Link>
        </Typography>
      ) : null}
    </Wrapper>
  )
}

export default Login
