import React, { useRef, useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  slider: {
    boxSizing: 'border-box',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    '-webkit-touch-callout': 'none',
    '-khtml-user-select': 'none',
    '-ms-touch-action': 'pan-y',
    'touch-action': 'pan-y',
    '-webkit-tap-highlight-color': 'transparent',
    position: 'relative',
    display: 'block',
    height: '100%',
    '& [class*="slick-list"],& [class*="slick-slider"],& [class*="slick-track"]': {
      position: 'relative',
      display: 'block'
    },
    '& [class*="slick-list"]': {
      overflow: 'hidden',
      margin: 0,
      padding: 0,
      '-webkit-transform': 'translate3d(0, 0, 0)',
      '-moz-transform': 'translate3d(0, 0, 0)',
      '-ms-transform': 'translate3d(0, 0, 0)',
      '-o-transform': 'translate3d(0, 0, 0)',
      transform: 'translate3d(0, 0, 0)',
      height: '100%'
    },
    '& [class*="slick-track"]': {
      top: 0,
      left: 0,
      '-webkit-transform': 'translate3d(0, 0, 0)',
      '-moz-transform': 'translate3d(0, 0, 0)',
      '-ms-transform': 'translate3d(0, 0, 0)',
      '-o-transform': 'translate3d(0, 0, 0)',
      transform: 'translate3d(0, 0, 0)',
      height: '100%'
    },
    '& [class*="slick-slide"]': {
      display: 'none',
      float: 'left',
      height: '100%',
      minHeight: '1px',
      '& > div:first-child': {
        height: '100%'
      }
    },
    '& [dir=\'rtl\'] [class*="slick-slide"]': {
      float: 'right'
    },
    '& [class*="slick-initialized"],& [class*="slick-slide"]': {
      display: 'block'
    }
  },
  // @ts-ignore
  arrows: ({ height, colorArrows }) => ({
    cursor: 'pointer',
    position: 'absolute',
    height: `${height}px`,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: colorArrows ? colorArrows : theme.palette.text.secondary
    }
  }),
  // @ts-ignore
  indicators: ({ height }) => ({
    position: 'absolute',
    top: `${height - 96}px`,
    left: 0,
    right: 0,
    width: 'fit-content',
    margin: '0 auto',
    zIndex: 100
  }),
  // @ts-ignore
  dot: ({ colorIndicators }) => ({
    height: '8px',
    width: '8px',
    background: theme.palette.text.disabled,
    opacity: '0.3',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0px 4px 0px 4px',
    '&:hover': {
      background: colorIndicators
        ? colorIndicators
        : theme.palette.primary.main,
      opacity: 1,
      cursor: 'pointer'
    }
  }),
  // @ts-ignore
  dotActive: ({ colorIndicators }) => ({
    background: colorIndicators ? colorIndicators : theme.palette.primary.main,
    opacity: 1,
    cursor: 'pointer'
  })
}))

export interface Props {
  /**
   * The content of the component.
   */
  children?: NonNullable<React.ReactNode>
  /**
   * If 'true', show the indicators
   */
  showIndicators?: boolean
  /**
   * If 'true', show the arrows
   */
  showArrows?: boolean
  /**
   * The color of the indicators
   */
  colorIndicators?: string
  /**
   * The color of the arrows
   */
  colorArrows?: string
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const StatusHeroSwipe: React.FC<Props> = ({
  children,
  showIndicators = true,
  showArrows = true,
  colorIndicators,
  colorArrows,
  style,
  className
}) => {
  const slider = useRef()
  const [getHeight, setGetHeight] = useState<number[]>([])
  const [height, setHeight] = useState<number | undefined>(undefined)
  const [index, setIndex] = useState<number>(0)
  const [click, setClick] = useState<boolean>(false)
  const [childrenCount] = useState<number>(React.Children.count(children))
  const classes = useStyles({ height, colorIndicators, colorArrows })

  useEffect(() => {
    if (childrenCount === getHeight.length) {
      setHeight(Math.max(...getHeight))
    }
  }, [getHeight, childrenCount])

  useEffect(() => {
    if (click) {
      // @ts-ignore
      slider.current.slickGoTo(index)
    }
  }, [click, index])

  const handleIndexChange = (value: number, indicatorClick: boolean) => {
    setClick(indicatorClick)
    setIndex(value)
  }

  const handleClickArrow = (direction: 'forward' | 'back') => {
    if (direction === 'forward' && index !== childrenCount - 1) {
      handleIndexChange(index + 1, true)
    }
    if (direction === 'back' && index !== 0) {
      handleIndexChange(index - 1, true)
    }
  }

  return (
    <Wrapper style={style} className={className}>
      <div>
        <Slider
          // @ts-ignore
          ref={slider}
          className={classes.slider}
          afterChange={(index) => handleIndexChange(index, false)}
          initialSlide={index}
          arrows={false}
          infinite={false}
        >
          {children &&
            React.Children.map(children, (child) => {
              return (
                // @ts-ignore
                React.cloneElement(child, {
                  componentHeight: height,
                  getHeight: getHeight,
                  setGetHeight: setGetHeight
                })
              )
            })}
        </Slider>
      </div>
      {showArrows && (
        <React.Fragment>
          <div
            onClick={() => handleClickArrow('back')}
            style={{ left: '8px' }}
            className={classes.arrows}
          >
            <ArrowBackIos />
          </div>
          <div
            onClick={() => handleClickArrow('forward')}
            style={{ right: '8px' }}
            className={classes.arrows}
          >
            <ArrowForwardIos />
          </div>
        </React.Fragment>
      )}
      {showIndicators && (
        <div className={classes.indicators}>
          {children &&
            getHeight.map((_indicator, i) => (
              <span
                key={i}
                className={
                  i === index
                    ? clsx(classes.dot, classes.dotActive)
                    : clsx(classes.dot)
                }
                onClick={() => handleIndexChange(i, true)}
              />
            ))}
        </div>
      )}
    </Wrapper>
  )
}

export default StatusHeroSwipe
