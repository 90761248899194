import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Slider from 'react-slick'
import Wrapper from '../common/Wrapper'
import Container from '../Container/Container'
import Whitelabel from '../Whitelabel/Whitelabel'

const useStyles = makeStyles((theme) => ({
  root: {
    '& [class*="MuiBackdrop-root"]': {
      position: 'absolute'
    },
    '& *': {
      boxSizing: 'border-box'
    }
  },
  fullScreen: {
    '& [class*="MuiDialog-paper"]': {
      height: '100%',
      width: '100%'
    }
  },
  dialog: {
    '& [class*="MuiDialog-paper"]': {
      height: '100%',
      width: '100%',
      '@media (min-height: 900px)': {
        height: '75%'
      },
      '@media (min-height: 1200px)': {
        height: '66%'
      },
      '@media (min-height: 1500px)': {
        height: '50%'
      }
    }
  },
  slider: {
    boxSizing: 'border-box',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    '-webkit-touch-callout': 'none',
    '-khtml-user-select': 'none',
    '-ms-touch-action': 'pan-y',
    'touch-action': 'pan-y',
    '-webkit-tap-highlight-color': 'transparent',
    position: 'relative',
    display: 'block',
    height: '100%',
    '& [class*="slick-list"],& [class*="slick-slider"],& [class*="slick-track"]': {
      position: 'relative',
      display: 'block'
    },
    '& [class*="slick-list"]': {
      overflow: 'hidden',
      margin: 0,
      padding: 0,
      '-webkit-transform': 'translate3d(0, 0, 0)',
      '-moz-transform': 'translate3d(0, 0, 0)',
      '-ms-transform': 'translate3d(0, 0, 0)',
      '-o-transform': 'translate3d(0, 0, 0)',
      transform: 'translate3d(0, 0, 0)',
      height: '100%'
    },
    '& [class*="slick-track"]': {
      top: 0,
      left: 0,
      '-webkit-transform': 'translate3d(0, 0, 0)',
      '-moz-transform': 'translate3d(0, 0, 0)',
      '-ms-transform': 'translate3d(0, 0, 0)',
      '-o-transform': 'translate3d(0, 0, 0)',
      transform: 'translate3d(0, 0, 0)',
      height: '100%'
    },
    '& [class*="slick-slide"]': {
      display: 'none',
      float: 'left',
      height: '100%',
      minHeight: '1px',
      '& > div:first-child': {
        height: '100%'
      }
    },
    '& [dir=\'rtl\'] [class*="slick-slide"]': {
      float: 'right'
    },
    '& [class*="slick-initialized"],& [class*="slick-slide"]': {
      display: 'block'
    }
  },
  screen: {
    height: '100%'
  },
  addBorderTop: {
    borderTop: '64px solid transparent'
  },
  text: {
    color: theme.palette.text.primary,
    paddingTop: '16px',
    paddingBottom: '5px'
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  subtitleNoTitle: {
    paddingTop: '16px',
    color: theme.palette.text.secondary
  },
  indicators: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: 'fit-content',
    margin: '0 auto',
    zIndex: 100
  },
  // @ts-ignore
  dot: ({ colorIndicators }) => ({
    height: '8px',
    width: '8px',
    background: theme.palette.text.disabled,
    opacity: '0.3',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0px 4px 0px 4px',
    '&:hover': {
      background: colorIndicators
        ? colorIndicators
        : theme.palette.primary.main,
      opacity: 1,
      cursor: 'pointer'
    }
  }),
  // @ts-ignore
  dotActive: ({ colorIndicators }) => ({
    background: colorIndicators ? colorIndicators : theme.palette.primary.main,
    opacity: 1,
    cursor: 'pointer'
  }),
  bgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: -2
  },
  colorOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1
  },
  button: {
    width: '100%',
    padding: '4px',
    textAlign: 'center'
  }
}))

export interface OnboardingScreen {
  color?: string
  backgroundColor?: string
  backgroundImage?: string
  image?: string | React.ReactNode
  title?: string
  subtitle?: string
}

export interface Props {
  /**
   * An array of `screen` objects. Object has the following optional properties:
   * @param {object} color What color to use.
   * @param {object} backgroundColor What background color to use.
   * @param {object} backgroundImage What background image to use.
   * @param {object} image What image to use. Can be a string or a React Node.
   * @param {object} title What title to use.
   * @param {object} subtitle What subtitle to use.
   */
  screens?: OnboardingScreen[]
  /**
   * AppBar component
   */
  appbar?: React.ReactNode
  /**
   * If 'true', show the indicators
   */
  showIndicators?: boolean
  /**
   * The color of the indicators
   */
  colorIndicators?: string
  /**
   * The components displayed at the bottom of the screen.
   */
  actionComponents?: React.ReactNode[]
  /**
   * The direction of the action components
   */
  actionDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component's content.
   * The component' content width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const Onboarding: React.FC<Props> = ({
  appbar,
  screens = [],
  showIndicators = true,
  colorIndicators,
  actionComponents = [],
  actionDirection = 'row',
  style,
  className,
  maxWidth = 'sm'
}) => {
  const classes = useStyles({ colorIndicators })
  const [index, setIndex] = useState<number>(0)
  const [click, setClick] = useState<boolean>(false)
  const slider = useRef()
  const theme = useTheme()
  const [bottomValue, setBottomValue] = useState<number>(0)
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const whitelabel = useMediaQuery(theme.breakpoints.down('sm'))

  // set bottom value for the content area (area above buttons / action components)
  useEffect(() => {
    if (actionDirection === 'column' || actionDirection === 'column-reverse') {
      let value: number = whitelabel ? 220 : 176
      if (actionComponents.length >= 2) {
        value = whitelabel ? 192 : 148
        for (let i = 2; i <= actionComponents.length; i++) {
          // 56 is the height of the button
          value += 56
        }
      }
      setBottomValue(value)
    } else {
      if (whitelabel) {
        setBottomValue(220)
      } else {
        setBottomValue(176)
      }
    }
  }, [whitelabel, actionDirection, actionComponents.length])

  useEffect(() => {
    if (click) {
      // @ts-ignore
      slider.current.slickGoTo(index)
    }
  }, [click, index])

  let setMaxWidth: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false = false
  if (maxWidth) {
    setMaxWidth = maxWidth
  }

  const handleIndexChange = (value: number, indicatorClick: boolean) => {
    setClick(indicatorClick)
    setIndex(value)
  }

  return (
    <Wrapper style={style} className={className}>
      <Dialog
        classes={{ root: classes.root }}
        style={{ position: 'absolute' }}
        className={clsx({
          [classes.fullScreen]: fullScreen,
          [classes.dialog]: !fullScreen
        })}
        fullScreen={fullScreen}
        open
        maxWidth={maxWidth}
      >
        {appbar && (
          <Container style={{ zIndex: 1 }} maxWidth={setMaxWidth}>
            {appbar}
          </Container>
        )}
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            textAlign: 'center'
          }}
        >
          <Slider
            // @ts-ignore
            ref={slider}
            className={classes.slider}
            afterChange={(index) => handleIndexChange(index, false)}
            initialSlide={index}
            arrows={false}
            infinite={false}
          >
            {screens.length > 0
              ? screens.map((screen: OnboardingScreen, i: number) => {
                  return (
                    <div key={i} className={classes.screen}>
                      <div
                        style={{
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          height: '100%',
                          backgroundColor: !screen.backgroundImage
                            ? `${screen.backgroundColor}`
                            : ''
                        }}
                        className={clsx({
                          [classes.addBorderTop]: !screen.backgroundImage
                        })}
                      >
                        {screen.backgroundImage ? (
                          <div
                            className={classes.bgImage}
                            style={{
                              backgroundImage: `url(${screen.backgroundImage})`
                            }}
                          />
                        ) : null}
                        {screen.backgroundImage && screen.backgroundColor ? (
                          <div
                            className={classes.colorOverlay}
                            style={{
                              backgroundColor: `${screen.backgroundColor}`
                            }}
                          />
                        ) : null}
                        <div
                          style={{
                            position: 'absolute',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            bottom: `${bottomValue}px`,
                            top: screen.backgroundImage ? '140px' : '100px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end'
                          }}
                        >
                          {screen.image && (
                            <Container
                              style={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-end'
                              }}
                              maxWidth={setMaxWidth}
                            >
                              {typeof screen.image === 'string' ||
                              screen.image instanceof String ? (
                                <img
                                  style={{
                                    maxHeight: '100%',
                                    maxWidth: '100%'
                                  }}
                                  src={screen.image as string}
                                  alt=""
                                />
                              ) : (
                                <div>{screen.image}</div>
                              )}
                            </Container>
                          )}
                          {screen.title && (
                            <Container maxWidth={setMaxWidth}>
                              <Typography
                                variant="h5"
                                style={{ color: screen.color }}
                                className={classes.text}
                              >
                                {screen.title}
                              </Typography>
                            </Container>
                          )}
                          {screen.subtitle && (
                            <Container maxWidth={setMaxWidth}>
                              <Typography
                                variant="body1"
                                style={{ color: screen.color }}
                                className={clsx({
                                  [classes.subtitle]: screen.title,
                                  [classes.subtitleNoTitle]: !screen.title
                                })}
                              >
                                {screen.subtitle}
                              </Typography>
                            </Container>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })
              : null}
          </Slider>
        </div>
        {actionComponents.length > 0 && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              bottom: whitelabel
                ? showIndicators
                  ? '100px'
                  : '56px'
                : showIndicators
                ? '68px'
                : '24px'
            }}
          >
            <Container defaultPadding maxWidth={setMaxWidth}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: actionDirection,
                  alignItems: 'center'
                }}
              >
                {actionComponents.map(
                  (component: React.ReactNode, i: number) => (
                    <div key={i} className={classes.button}>
                      {component}
                    </div>
                  )
                )}
              </div>
            </Container>
          </div>
        )}
        {showIndicators && (
          <div
            className={classes.indicators}
            style={{ bottom: whitelabel ? '56px' : '24px' }}
          >
            {screens.map((_screen: OnboardingScreen, i: number) => {
              return (
                <span
                  key={i}
                  className={
                    i === index
                      ? clsx(classes.dot, classes.dotActive)
                      : clsx(classes.dot)
                  }
                  onClick={() => handleIndexChange(i, true)}
                />
              )
            })}
          </div>
        )}
        <Whitelabel
          style={{
            display: whitelabel ? '' : 'none',
            position: 'absolute',
            bottom: 0,
            width: '100%'
          }}
        />
      </Dialog>
    </Wrapper>
  )
}

export default Onboarding
