import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import { default as SpeedDialMaterial } from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import { default as SpeedDialActionMaterial } from '@material-ui/lab/SpeedDialAction'
import Icons from '../common/Icons'

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'relative'
  },
  speedDialColor: (props) => ({
    // @ts-ignore
    color: props.color || theme.palette.common.white,
    // @ts-ignore
    backgroundColor: props.backgroundColor || theme.palette.secondary.main,
    '&:hover': {
      // @ts-ignore
      color: props.color || theme.palette.common.white,
      // @ts-ignore
      backgroundColor: props.backgroundColor || theme.palette.secondary.main
    }
  }),
  labelStyles: {
    fontStyle: theme.typography.caption.fontStyle,
    fontWeight: theme.typography.caption.fontWeight,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    letterSpacing: theme.typography.caption.letterSpacing,
    color: theme.palette.common.white,
    background: theme.palette.common.black,
    opacity: '1',
    padding: '8px 16px',
    whiteSpace: 'nowrap'
  },
  backdrop: {
    zIndex: theme.zIndex.speedDial
  }
}))

export interface SpeedDialAction {
  label?: string
  icon?: string
  onClick?: React.MouseEventHandler
}

export interface Props {
  /**
   * An array of objects with optional label, icon and onClick properties.
   */
  actions?: SpeedDialAction[]
  /**
   * If 'true' backdrop will be disabled. (Default disabled if zero actions).
   */
  backdropDisable?: boolean
  /**
   * What color to use (plus icon).
   */
  color?: string
  /**
   * What background color to use (fab).
   */
  backgroundColor?: string
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
}

const SpeedDial: React.FC<Props> = ({
  actions = [],
  backdropDisable = false,
  color = '',
  backgroundColor = '',
  style,
  className
}) => {
  const props = { color, backgroundColor }
  const classes = useStyles(props)
  const [open, setOpen] = useState<boolean>(false)

  const handleClose = (_event: object, reason: string) => {
    if (reason !== 'toggle') {
      setOpen(false)
    }
  }
  const handleOpen = (_event: object, _reason: string) => {
    setOpen(true)
  }

  return (
    <React.Fragment>
      {actions.length > 0 && !backdropDisable && (
        <Backdrop className={classes.backdrop} open={open} />
      )}
      <SpeedDialMaterial
        style={style}
        className={className}
        ariaLabel="SpeedDial tooltip"
        classes={{ root: classes.speedDial, fab: classes.speedDialColor }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action, i) => {
          let icon: string | undefined = action.icon || ''
          if (!Object.prototype.hasOwnProperty.call(Icons, icon)) {
            icon = undefined
          }
          return (
            <SpeedDialActionMaterial
              key={i}
              icon={icon ? React.createElement(Icons[icon]) : null}
              tooltipTitle={action.label}
              tooltipOpen
              onClick={action.onClick}
              classes={{
                staticTooltipLabel: classes.labelStyles
              }}
            />
          )
        })}
      </SpeedDialMaterial>
    </React.Fragment>
  )
}

export default SpeedDial
