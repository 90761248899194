import React, { useRef, useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Icons from '../common/Icons'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '10px'
  },
  // @ts-ignore
  containerWithHeight: ({ componentHeight }) => ({
    height: `${componentHeight}px`,
    display: 'flex',
    alignItems: 'center'
  }),
  content: {
    textAlign: 'center',
    padding: '48px 16px'
  },
  // @ts-ignore
  icon: ({ iconSize }) => ({
    '& svg': {
      fontSize: `${iconSize}px`
    }
  }),
  videoContainer: {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: '56.25%'
  },
  videoStyles: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0
  },
  noticeText: {
    paddingTop: '8px'
  },
  textContent: {
    paddingTop: '8px'
  },
  body: {
    padding: '8px 16px',
    textAlign: 'center'
  },
  bodyWithComponent: {
    marginBottom: '-24px'
  },
  reverse: {
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  // @ts-ignore
  componentContainer: ({ actionHeight }) => ({
    position: 'relative',
    height: `${actionHeight}px`,
    marginBottom: '38px',
    display: 'flex',
    justifyContent: 'center'
  }),
  component: {
    position: 'absolute',
    top: '50%',
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  // @ts-ignore
  actionContent: ({ actionHeight }) => ({
    position: 'relative',
    marginTop: `-${actionHeight}px`
  })
}))

export interface Props {
  /**
   *  Icon uses Material UI icon names.
   */
  icon?: string
  /**
   * Icon's size in pixels. (Default 80).
   */
  iconSize?: number
  /**
   * Image either as a ReactNode or as a string
   */
  image?: React.ReactNode | string
  /**
   * Video's url
   */
  video?: string
  /**
   * What color for content to use
   */
  color?: string
  /**
   * What background color to use
   */
  backgroundColor?: string
  /**
   * The notice text content.
   */
  noticeText?: string | React.ReactNode
  /**
   * The headline content.
   */
  headline?: string
  /**
   * The title content.
   */
  title?: string
  /**
   * The subtitle content.
   */
  subtitle?: string
  /**
   * The body content.
   */
  body?: string
  /**
   * If 'true', reverses headline, title and subtitle order.
   */
  reverseText?: boolean
  /**
   * The component to be displayed inside the content area.
   */
  contentComponent?: React.ReactNode
  /**
   * The component to be displayed at the bottom of the content area.
   */
  actionComponent?: React.ReactNode
  /**
   * Height of the component
   */
  componentHeight?: number
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
  /**
   * @ignore
   */
  getHeight?: number[]
  /**
   * @ignore
   */
  setGetHeight?: React.Dispatch<React.SetStateAction<number[]>>
}

const StatusHero: React.FC<Props> = ({
  icon = '',
  iconSize = 80,
  image,
  video,
  color = '#FFFFFF',
  backgroundColor = 'rgba(0, 44, 56, 0.87)',
  noticeText,
  headline,
  title,
  subtitle,
  body,
  reverseText = false,
  contentComponent,
  actionComponent,
  componentHeight,
  style,
  className,
  maxWidth = 'sm',
  getHeight,
  setGetHeight
}) => {
  const [actionHeight, setActionHeight] = useState<number>(0)
  const heightRef = useRef(null)
  const actionHeightRef = useRef(null)
  const classes = useStyles({ iconSize, actionHeight, componentHeight })

  useEffect(() => {
    if (getHeight && setGetHeight) {
      if (heightRef.current) {
        setGetHeight((arr: number[]) => [
          ...arr,
          // @ts-ignore
          heightRef.current.clientHeight + heightRef.current.clientHeight / 2.5
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heightRef])

  useEffect(() => {
    if (actionHeightRef.current) {
      // @ts-ignore
      setActionHeight(actionHeightRef.current.clientHeight)
    }
  }, [actionHeightRef, actionComponent])

  let iconType: string | undefined = icon
  if (!Object.prototype.hasOwnProperty.call(Icons, icon)) {
    iconType = undefined
  }

  return (
    <Wrapper style={style} className={className}>
      <div
        ref={heightRef}
        className={clsx({
          [classes.container]: !componentHeight,
          [classes.containerWithHeight]: componentHeight
        })}
        style={{ backgroundColor }}
      >
        <Wrapper maxWidth={maxWidth}>
          <div className={classes.content}>
            {iconType ? (
              <div className={classes.icon} style={{ color }}>
                {React.createElement(Icons[iconType])}
              </div>
            ) : null}
            {image ? (
              typeof image === 'string' || image instanceof String ? (
                <img
                  style={{ maxWidth: '100%' }}
                  src={image as string}
                  alt="status"
                />
              ) : (
                <div>{image}</div>
              )
            ) : null}
            {video ? (
              <div className={classes.videoContainer}>
                <iframe
                  className={classes.videoStyles}
                  src={video}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  title="video"
                />
              </div>
            ) : null}
            {contentComponent ? <div>{contentComponent}</div> : null}
            {noticeText ? (
              <Typography
                className={classes.noticeText}
                style={{ color }}
                variant="h3"
              >
                {noticeText}
              </Typography>
            ) : null}
            {headline || title || subtitle ? (
              <div
                className={clsx(classes.textContent, {
                  [classes.reverse]: reverseText
                })}
              >
                {headline ? (
                  <Typography style={{ color }} variant="h5">
                    {headline}
                  </Typography>
                ) : null}
                {title ? (
                  <Typography style={{ color }} variant="h6">
                    {title}
                  </Typography>
                ) : null}
                {subtitle ? (
                  <Typography style={{ color }} variant="subtitle1">
                    {subtitle}
                  </Typography>
                ) : null}
              </div>
            ) : null}
          </div>
          {!componentHeight && (
            <React.Fragment>
              {body ? (
                <Typography
                  className={clsx(classes.body, {
                    [classes.bodyWithComponent]: actionComponent
                  })}
                  style={{ color }}
                  variant="body2"
                >
                  {body}
                </Typography>
              ) : null}
              {actionComponent && (
                <div className={classes.componentContainer}>
                  <div ref={actionHeightRef} className={classes.component}>
                    {actionComponent}
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </Wrapper>
      </div>
      {componentHeight && (
        <React.Fragment>
          <div className={classes.actionContent}>
            {body ? (
              <Typography
                style={{
                  color,
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
                variant="body2"
              >
                {body}
              </Typography>
            ) : null}
            {actionComponent && (
              <div className={classes.componentContainer}>
                <div ref={actionHeightRef} className={classes.component}>
                  {actionComponent}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </Wrapper>
  )
}

export default StatusHero
