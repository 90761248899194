import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { default as MaterialRadio } from '@material-ui/core/Radio'
import Wrapper from '../common/Wrapper'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.text.primary
  },
  secondary: {
    display: 'block',
    color: theme.palette.text.secondary
  },
  radio: {
    '&:hover': {
      background: `none !important`
    }
  }
}))

export interface Props {
  /**
   * The value of the component
   */
  value?: string | number
  /**
   * If `true`, the component is checked.
   */
  checked?: boolean
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * What label to use
   */
  label?: string
  /**
   * Array of strings. Subtitles are shown as rows below the label
   */
  subtitles?: string[]
  /**
   * If 'true', the component will be disabled
   */
  disabled?: boolean
  /**
   * If 'true, the left and right padding is removed
   */
  disableGutters?: boolean
  /**
   * If 'true', compact vertical padding designed for keyboard and mouse input will be used.
   */
  dense?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const Radio: React.FC<Props> = ({
  value = '',
  checked = false,
  onChange,
  label,
  subtitles = [],
  disabled = false,
  disableGutters = false,
  dense = false,
  style,
  className,
  maxWidth
}) => {
  const classes = useStyles()

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <List disablePadding dense={dense}>
        <ListItem
          disableGutters={disableGutters}
          style={{ cursor: 'pointer' }}
          // @ts-ignore
          onClick={onChange}
        >
          <ListItemIcon>
            <MaterialRadio
              className={classes.radio}
              edge="start"
              color="secondary"
              disabled={disabled}
              value={value}
              checked={checked}
              onChange={onChange}
              disableRipple
              inputProps={{
                'aria-label': `${value}`
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <React.Fragment>
                {label ? (
                  <Typography variant="subtitle1" className={classes.primary}>
                    {label}
                  </Typography>
                ) : null}
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                {subtitles.map((subtitle, i) => (
                  <Typography
                    key={i}
                    className={classes.secondary}
                    variant="body2"
                    component="span"
                  >
                    {subtitle}
                  </Typography>
                ))}
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    </Wrapper>
  )
}

export default Radio
